import { createApp } from "vue";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import zhCn from "element-plus/es/locale/lang/zh-cn";
import App from "./App.vue";
import router from "@/router/index";
import store from "@/store/index";
import request from "@/request/index.js";
import utils from "./common/utils.js";
import config from "@/config/http.js";
import menus from "@/router/menus.js";
const app = createApp(App);

app.config.globalProperties.$http = request;

app.config.globalProperties.$utils = utils;

app.config.globalProperties.$config = config;

// // 注册所有图标
// import * as ElementPlusIconsVue from '@element-plus/icons-vue';

// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
// 	app.component(key, component)
// }
let load = 0;
//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 中国中小企业协会企业合规专业委员会`;
  if (!store.state.bannerInfo) {
    next();
  } else {
    next();
  }
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

app.use(ElementPlus, {
  // locale: zhCn,
});

app.use(router);
app.use(store);
app.mount("#app");
