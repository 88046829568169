import axios from "axios";
import route from "../router/index";

// var Base_PROJECT = "/committee";
// const Base_URL = "http://139.196.165.152/committee-api";

const Base_PROJECT = "";
const Base_URL = "http://casme-ecpc.ca-sme.org/committee-api";
//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // config.headers = { token: localStorage.getItem("psra_token") };
    return config;
  },
  (error) => {
    return Promise.reject(err);
  }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
  (res) => {
    if (res.data && res.data != "") {
      return res;
    }
  },
  (err) => {
    return Promise.reject(err.response);
  }
);
var request = {
  /**
   * 封装get方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  get(url, params = {}, baseURL = Base_URL) {
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        params: params,
        baseURL: baseURL,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async_get(url, params = {}, baseURL = Base_URL) {
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        async: false,
        params: params,
        baseURL: baseURL,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * 封装post请求
   * @param url
   * @param data
   * @returns {Promise}
   */
  post(url, params, config) {
    return new Promise((resolve, reject) => {
      axios
        .post(Base_URL + url, params, config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * 封装delete请求
   * @param delete
   * @param data
   * @returns {Promise}
   */
  del(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.delete(Base_URL + url, data).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  /**
   * 封装put请求
   * @param url
   * @param data
   * @returns {Promise}
   */
  put(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.put(Base_URL + url, data).then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  project() {
    return Base_PROJECT;
  },
};

export default request;
