<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import request from "@/request/request.js";
var mixins = {
  computed: {
    isPc() {
      return this.$utils.browser();
    },
  },
  created() {
    if (!this.isPc) {
      console.log(request.project());
      location.href = location.origin + request.project() + "/h5";
    }
  },
};

export default {
  mixins: [mixins],
};
</script>

<style lang="less">
@import "./assets/css/main.css";
body {
  display: block;
  margin: 0px;
}
.el-menu--horizontal {
  display: flex;
  flex-wrap: nowrap;
  /* border-bottom: solid 1px var(--el-menu-border-color); */
  border-right: none;
}
</style>
