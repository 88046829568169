// 节流
function throttle(func, delay) {
  var timer = null;
  var startTime = Date.now();
  return function () {
    var curTime = Date.now();
    var remaining = delay - (curTime - startTime);
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    if (remaining <= 0) {
      func.apply(context, args);
      startTime = Date.now();
    } else {
      timer = setTimeout(func, remaining);
    }
  };
}

// 秒转时分秒
function formatSeconds(time) {
  // 转换为式分秒
  let h = parseInt((time / 60 / 60) % 24);
  h = h < 10 ? "0" + h : h;
  let m = parseInt((time / 60) % 60);
  m = m < 10 ? "0" + m : m;
  let s = parseInt(time % 60);
  s = s < 10 ? "0" + s : s;

  return `${h}:${m}:${s}`; // 作为返回值返回
}

//去除重复的数组对象
function filterArr(arr, key) {
  let hash = {};
  return arr.reduce((ss, item) => {
    hash[item[key]] ? "" : (hash[item[key]] = true && ss.push(item));
    return ss;
  }, []);
}

//下载文件
function downloadFile(file, name = "我是文件", type, callback) {
  const blob = new Blob([file]);
  const fileName = `${name}.${type}`;
  if (!callback) {
    const linkNode = document.createElement("a");
    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
    linkNode.style.display = "none";
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click(); //模拟在按钮上的一次鼠标单击
    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);
  } else {
    callback(URL.createObjectURL(blob));
  }
}

//已复制
function copyCommand(e) {
  let transfer = document.createElement("input");
  document.body.appendChild(transfer);
  transfer.value = e; // 这里表示想要复制的内容
  transfer.focus();
  transfer.select();
  if (document.execCommand("copy")) {
    document.execCommand("copy");
  }
  transfer.blur();
  //console.log('复制成功');
  document.body.removeChild(transfer);
}

// 是否 pc
function browser() {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    return false;
  } else {
    return true;
  }
}

export default {
  throttle,
  formatSeconds,
  filterArr,
  downloadFile,
  copyCommand,
  browser,
};
