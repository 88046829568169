const menus = [
  {
    name: "index",
    title: "首页",
    path: "/",
  },
  {
    name: "AboutAssociation",
    title: "关于专委会",
    path: "/AboutAssociation",
  },
  {
    name: "noticeAnnouncement",
    title: "通知公告",
    path: "/noticeAnnouncement",
  },

  {
    name: "TrainingInformation",
    title: "培训信息",
    path: "/TrainingInformation",
  },
  {
    name: "QueryCertificate",
    title: "证书查询",
    path: "/QueryCertificate",
  },
  {
    name: "ExpertAdvisor",
    title: "专家顾问",
    path: "/ExpertAdvisor",
  },
  {
    name: "TradeNews",
    title: "行业研究",
    path: "/TradeNews",
  },
  {
    name: "PolicyDynamic",
    title: "政策动态",
    path: "/PolicyDynamic",
  },
  {
    name: "ExperienceExchange",
    title: "经验交流",
    path: "/ExperienceExchange",
  },
  {
    name: "ContactUs",
    title: "联系我们",
    path: "/ContactUs",
  },
];

export default menus;
