import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/",
        name: "index",
        meta: {
          title: "首页",
        },
        component: () => import("../views/Home/index.vue"),
      },
      {
        name: "AboutAssociation",
        meta: {
          title: "关于专委会",
        },
        path: "/AboutAssociation",
        component: () =>
          import("../views/AboutAssociation/aboutAssociation.vue"),
      },
      {
        path: "/NoticeAnnouncement",
        name: "NoticeAnnouncement",
        meta: {
          title: "通知公告",
        },
        component: () =>
          import("../views/NoticeAnnouncement/noticeAnnouncement.vue"),
      },
      {
        name: "noticeDetail",
        path: "/noticeDetail",
        component: () =>
          import("../views/NoticeAnnouncement/components/noticeDetail.vue"),
        meta: {
          title: "公告详情",
        },
      },
      {
        path: "/TrainingInformation",
        name: "TrainingInformation",
        meta: {
          title: "培训信息",
        },
        component: () =>
          import("../views/TrainingInformation/trainingInformation.vue"),
      },
      {
        path: "/trainRegistration",
        name: "trainRegistration",
        meta: {
          title: "培训报名",
        },
        component: () =>
          import("../views/TrainingInformation/trainRegistration.vue"),
      },
      {
        path: "/QueryCertificate",
        name: "QueryCertificate",
        meta: {
          title: "证书查询",
        },
        component: () =>
          import("../views/QueryCertificate/queryCertificate.vue"),
      },
      {
        path: "/queryTrueCertificate",
        name: "queryTrueCertificate",
        meta: {
          title: "证书查询(真)",
        },
        component: () =>
          import("../views/QueryCertificate/queryTrueCertificate.vue"),
      },
      {
        path: "/queryFalseCertificate",
        name: "queryFalseCertificate",
        meta: {
          title: "证书查询(假)",
        },
        component: () =>
          import("../views/QueryCertificate/queryFalseCertificate.vue"),
      },
      {
        path: "/ExpertAdvisor",
        name: "ExpertAdvisor",
        meta: {
          title: "专家顾问",
        },
        component: () => import("../views/ExpertAdvisor/expertAdvisor.vue"),
      },
      {
        name: "TradeNews",
        path: "/TradeNews",
        component: () => import("../views/TradeNews/tradeNews.vue"),
        meta: {
          title: "行业研究",
        },
      },
      {
        name: "PolicyDynamic",
        meta: {
          title: "政策动态",
        },
        path: "/PolicyDynamic",
        component: () => import("../views/PolicyDynamic/policyDynamic.vue"),
      },
      {
        path: "/ExperienceExchange",
        name: "ExperienceExchange",
        meta: {
          title: "经验交流",
        },
        component: () =>
          import("../views/ExperienceExchange/experienceExchange.vue"),
      },
      {
        path: "/ContactUs",
        name: "ContactUs",
        meta: {
          title: "联系我们",
        },
        component: () => import("../views/ContactUs/contactUs.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
