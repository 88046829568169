import http from "./request";

//banner列表
const getBannerList = (data) => {
  return http.get("/web", data);
};
//外部链接 1：培训报名 2：友情链接
const getLinkList = (data) => {
  return http.get("/web/link", data);
};
//列表内容 1：政策动态 2：通知公告 3：行业研究
const getDataList = (data) => {
  return http.get("/web/list", data);
};
//列表详情 1：政策动态 2：通知公告 3：行业研究
const getDataDetail = (data) => {
  return http.get("/web/list/detail", data);
};
//富文本内容 type ：about: 关于专委会 expert:专家顾问 contact：联系我们 exper：经验交流
const getRichData = (data) => {
  return http.get("/web/rich", data);
};
//查询证书
const queryCertificate = (data) => {
  return http.post("/web/cerificate", data);
};
//查询证书
const queryCertificateType = (data) => {
  return http.get("web/certificate-type", data);
};
export default {
  getBannerList,
  getLinkList,
  getDataList,
  getDataDetail,
  getRichData,
  queryCertificate,
  queryCertificateType,
};
